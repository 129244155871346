<template>
    <v-row class="mt-5">
        <v-col cols="12" class="d-flex justify-space-between">
            <div>
                <div class="d-flex">
                    <v-btn
                        icon
                        color="primary"
                        class="elevation-1"
                        dense
                        @click="perPage--"
                        :disabled="perPage < 2"
                    >
                        <v-icon>mdi-minus</v-icon>
                    </v-btn>                    
                    <v-text-field
                        style="width: 60px;"
                        v-model="perPage"
                        hide-details
                        dense
                        solo
                        flat
                    />
                    <v-btn
                        icon
                        color="primary"
                        class="elevation-1"
                        dense
                        @click="perPage++"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </div>
                <div>
                    <p class="text-caption text-center">Itens por página</p>
                </div>                
            </div>
            <div v-if="pagesCounter > 1">
                <v-pagination
                    v-model="page"
                    :length="pagesCounter"
                    :total-visible="5"
                    next-icon="fas fa-angle-right"
                    prev-icon="fas fa-angle-left"
                    circle
                />
                <div>
                    <p class="text-caption text-center">Página atual</p>
                </div>   
            </div>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        props: {
            pagesCounter: Number,
            itemsPerPage: Number,
            currentPage: Number
        },
        computed: {
            page: {
                get: function() {
                    return this.currentPage
                },
                set: function(value) {
                    this.$emit('setCurrentPage', parseInt(value))
                }
            },
            perPage: {
                get: function() {
                    return this.itemsPerPage
                },
                set: function(value) {
                    this.$emit('setItemsPerPage', parseInt(value))
                }
            },
        }, 
    }
</script>